﻿var mayojs = (function() {


    return {
        getDate: function () {
            return new Date();
        },
        set: function (id, content, url) {
            $(id, content).attr({ 'src': url });
            return content;
        },
        init: function () {
            $(document).ready(function () {
                var toggleAffix = function (affixElement, wrapper, scrollElement) {

                    var height = affixElement.outerHeight(),
                        top = wrapper.offset().top;

                    if (scrollElement.scrollTop() >= top) {
                        wrapper.height(height);
                        affixElement.addClass("affix");
                    } else {
                        affixElement.removeClass("affix");
                        wrapper.height('auto');
                    }

                };

                $('#m-slideshow').carousel("cycle");
                $('[data-toggle="affix"]').each(function () {
                    var ele = $(this),
                        wrapper = $('<div></div>');

                    ele.before(wrapper);
                    $(window).on('scroll resize',
                        function () {
                            toggleAffix(ele, wrapper, $(this));
                        });

                    // init
                    toggleAffix(ele, wrapper, $(window));
                });

                // handle ctrl+p event
                $(document).on("keydown", function (e) {
                    if ((e.ctrlKey || e.metaKey) && e.keyCode === 80) {
                        // stop default print
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        handlePrinting();
                    }
                });
            });
        },
        // Currently this function is not in use, but if we need to do Dynamic embedding for Kaltura this function can handle that
        kalturaDynamicEmbed: function (targetId, entryId) {            
            var idToTarget = "kaltura_player_dynamic";
            const playerDiv = document.createElement("div");
            playerDiv.id = idToTarget;
            document.getElementById(targetId).appendChild(playerDiv);
            try {
                var kp = KalturaPlayer.setup({
                    targetId: idToTarget,
                    provider: {
                        partnerId: 1825021,
                        uiConfId: 55497922,
                        domain: window.location.hostname
                    },
                    //log: {
                    //    level: "DEBUG"
                    //}
                });
                kp.loadMedia({ entryId: entryId })
            }
            catch (e) {
                console.log(e.message)
            }
            
        },
        // V7 autoEmbed code
        kalturaAutoEmbed: function (targetId, entryId) {
            var idToTarget = "kaltura_player_dynamic";
            const playerDiv = document.createElement("div");
            playerDiv.id = idToTarget;
            document.getElementById(targetId).appendChild(playerDiv);                        
            const script = document.createElement("script");
            const uiconf_id = '55497922';
            const partnerId = "1825021";
            script.type = "text/javascript";            
            script.src = "https://cdnapisec.kaltura.com/p/" + partnerId + "/embedPlaykitJs/uiconf_id/" + uiconf_id + "?autoembed=true&targetId=" + idToTarget + "&entry_id=" + entryId; //+ "&config[provider]=%7B%22partnerId%22%3A1825021%2C%22uiConfId%22%3A55497922%2C%22domain%22%3A%22" + encodeURIComponent(window.location.hostname) + "%22%7D";
            document.body.appendChild(script)                       
        },
        // Currently this function is not in use, but if we need to do Iframe embedding for Kaltura this function can handle that
        kalturaIFrameEmbed: function (targetId, entryId) {
            var idToTarget = "kaltura_player_iframe";
            const playerDiv = document.createElement("div");
            playerDiv.id = idToTarget;
            document.getElementById(targetId).appendChild(playerDiv);
            const iframe = document.createElement("iframe");
            const uiconf_id = '55497922';
            const partnerId = "1825021";       
            iframe.type = "text/javascript";           
            iframe.src = "https://cdnapisec.kaltura.com/p/" + partnerId + "/embedPlaykitJs/uiconf_id/" + uiconf_id + "?iframeembed=true&entry_id=" + entryId;// + "&config[provider]=%7B%22%22%7D";
            iframe.allowFullscreen = true;
            iframe.setAttribute('webkitallowfullscreen', 'true');
            iframe.setAttribute('mozallowfullscreen', 'true');
            iframe.setAttribute('allow', 'autoplay *; fullscreen *; encrypted-media *');
            iframe.frameBorder = '0';
            $("#" + idToTarget).prepend(iframe);
        },
        loadAnalyticScript: function(pocId) {
            var isFound = false;
            var analytics = $("#m-analytics-script").val();
            if (analytics) {
                var scripts = document.getElementsByTagName("script");
                for (var i = 0; i < scripts.length; ++i) {
                    if (scripts[i].getAttribute('src') !== null && scripts[i].getAttribute('src').includes(analytics)) {
                        isFound = true;
                    }
                }
                var subject = $(".composite-pri-subject").map(function () { return this.value; })
                    .get().join('|');
                var focus = $(".composite-focus").map(function () { return this.value; })
                    .get().join('|');
                _dl = {
                    "version": "1.0.0",
                    "pocID": pocId,
                    "subject": subject,
                    "focus": focus, 
                    "clntBizID": "rchynoar-8yTmBCLeWhP3oSQ4CRohEQ1N",
                    "clntBizNm": "CD_WEB"
                };

                if (!isFound) {
                    var dynamicScripts = [analytics];

                    for (var i = 0; i < dynamicScripts.length; i++) {
                        var node = document.createElement('script');
                        node.src = dynamicScripts[i];
                        node.type = 'text/javascript';
                        node.async = false;
                        node.charset = 'utf-8';
                        document.getElementsByTagName('head')[0].appendChild(node);
                    }

                }
            }
        },
        loadTealiumScript: function (isProd) {
            var envString = isProd ? 'prod' : 'dev';

            var headerNode = document.createElement('script');
            headerNode.src = `//tagdelivery.mayoclinic.org/intcareplans/${envString}/utag.sync.js`;
            headerNode.type = 'text/javascript';
            $("head").first().prepend(headerNode);

            var bodyNode = document.createElement('script');
            bodyNode.type = 'text/javascript';
            bodyNode.text = `(function(a,b,c,d) {a='//tagdelivery.mayoclinic.org/intcareplans/${envString}/utag.js ';` +
                            `b=document;c='script';d=b.createElement(c);d.src=a;`+
                            `d.type='text/java'+c;d.async=true;` +
                            `a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a)})();`
            $("body").first().prepend(bodyNode);
        },
        doPrint: function () {      // print button click
            handlePrinting();
        }
    };

})(mayojs || {});

var handlePrinting = function () {
    var userAgent = navigator.userAgent;
    console.log("user agent:  " + navigator.userAgent);
    // only need to do after print event delay for mobile safari browser
    var afterPrintDelay = userAgent.match(/macintosh|iphone|ipad|ipod/gi) && !userAgent.match(/chrome|firefox|edg|opr/gi) ? 5000 : 0;
    console.log("print delay:  " + afterPrintDelay);
    var currentUrl = window.location.href;
    history.replaceState(history.state, '', '/');
    console.log('url before print ' + currentUrl);
    window.print();
    // before and after print events happen right away on safari
    setTimeout(function () {
        console.log('url after print ' + currentUrl);
        history.pushState(history.state, '', currentUrl);
        history.replaceState(history.state, '', currentUrl);
    }, afterPrintDelay);
};